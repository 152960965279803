<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      dark
    >
      <v-spacer></v-spacer>

      <div color="#008DBC" class="d-flex align-center">
        <v-img
          alt="Shop Local MO Logo"
          class=""
          contain
          src="./assets/logo_cleanup.png"
          transition="scale-transition"
          width="40px"
        />
        <span style="
            color: black;
            font-size: 2em;
            margin-left: 5px;
          "
        >
          Shop Local MO
        </span>
      </div>

      <v-spacer></v-spacer>
      <v-btn
        icon
        class="h3 ma-3"
        color="#008DBC"
        @click="signOut"
        v-if="user.email"
      >Sign Out</v-btn>
    </v-app-bar>

    <v-main>
      <router-view style="height: 100%;"/>
    </v-main>
    <Footer></Footer>
  </v-app>
</template>

<script>
import Vue from 'vue';
import Footer from './components/Footer.vue';
import { mapState, mapActions } from 'vuex'

export default Vue.extend({
  components: { Footer },
  name: 'App',

  computed: {
    ...mapState(['user'])
  },
  methods: {
    ...mapActions(['signOut'])
  },
  data: () => ({
    //
  }),
});
</script>

<style scoped>
  main {
    background-color: #008DBC;
  }
</style>