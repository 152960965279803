import { initializeApp } from 'firebase/app'
const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");
import credentials from './credentials'

const App = initializeApp(credentials.config)
const AppCheck = initializeAppCheck(App, {
  provider: new ReCaptchaV3Provider('6LcMfXwiAAAAABY-ovK5HimDuFF5gKMZERGFD25K'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

export { App, AppCheck }
