<!-- We are currently under construction, sign up for notifications and we will keep you informed as we make progress. -->
<!-- We sent you a verification  -->
<template>
  <div>
    <v-dialog 
      v-model="confirmDialog"
      width="500"  
    >
      <v-card>
        <v-card-title class="text-h5 theme-color">
          You're Signed up! 
        </v-card-title>

        <v-card-text class="py-5 px-10">
          We will let you know as we make progress on this application. Thank you for your interest!
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="confirmDialog = false"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card class="mx-5 mb-5">
      <v-card-title>
        Sign Up for Notifications
      </v-card-title>
      <v-card-subtitle>
        We are currently under construction, sign up for notifications and you 
        will have an insider&#39;s look into ShopLocalMO.com as we make progress.
      </v-card-subtitle>
      <v-card-text>
        <v-form 
          ref="form"
        >
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="firstName"
                  prepend-icon="mdi-account-circle"
                  label="First Name"
                  :rules="nameRules"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="lastName"
                  v-if="!belowMd"
                  label="Last Name"
                  :rules="nameRules"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="lastName"
                  v-if="belowMd"
                  prepend-icon="mdi-account-circle"
                  label="Last Name"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="email"
                  prepend-icon="mdi-email"
                  label="Email"
                  :rules="emailRules"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="phone"
                  prepend-icon="mdi-cellphone-sound"
                  label="Phone Number (Optional)"
                  :rules="phone ? phoneRules : ''"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-row>
        <v-col cols="12">
          <v-card-actions>
            <v-row>
              <v-col>
                <v-btn class="ml-14 white--text" color="#008DBC" @click="signUpForNotifications">Sign up</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
  import Vue from 'vue'
  import { DB, addDoc, collection } from '../firebase/db'

  export default Vue.extend({
    data: () => ({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      confirmDialog: false,
      phoneRules: [
        // v => !!v || "Phone is required",
        v => /[0-9]{10}/.test(v) || 'Phone must be a 10 digit number, no spaces, no non-digits',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      nameRules: [
        v => !!v || "Name is required",
      ],
    }),
    computed: {
      belowMd () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return true
          case 'sm': return true
          case 'md': return true
          case 'lg': return false
          case 'xl': return false
          default: return false
        }
      },
    },
    methods: {
      async signUpForNotifications() {
        try {
          if (this.$refs.form.validate()) {
            await addDoc(collection(DB, "alphaSubscribers"), {
              firstName: this.firstName,
              lastName: this.lastName,
              email: this.email,
              phone: this.phone,
            })
  
            this.confirmDialog = true

            this.$refs.form.reset()
          }

        } catch (e) {
          console.error("Error adding document: ", e);
        }
      }
    },
  })
</script>

<style scoped>
.theme-color {
  background-color: #008DBC;
  color: white;
}
</style>