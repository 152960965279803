
import router from '@/router'
import { Auth, signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged, signOut } from '@/firebase/auth'
import { DB, setDoc, doc, getDoc } from '@/firebase/db'
// import { getDoc } from 'firebase/firestore'
// import { onAuthStateChanged } from 'firebase/auth'

export default {
  state: {
    firstName: '',
    lastName: '',
    email: '',
    emailVerified: false,
    isAnonymous: false,
    uid: '',
    isAdmin: false,
  },
  getters: {
    user: (state) => state 
  },
  mutations: {
    updateUserState(state, userProfile) {
      state.firstName = userProfile.firstName
      state.lastName = userProfile.lastName
      state.email = userProfile.email
      state.emailVerified = Auth.currentUser.emailVerified
      state.isAnonymous = userProfile.isAnonymous
      state.uid = userProfile.uid
      state.isAdmin = userProfile.isAdmin
      if(router.currentRoute.name !== 'RegistrationLanding') {
        router.push({ name: 'RegistrationLanding' })
      }
    },
    resetUserState(state) {
      state.firstName = ''
      state.lastName = ''
      state.email = ''
      state.emailVerified = false
      state.isAnonymous = false
      state.uid = ''
      state.isAdmin = false
    },
  },
  actions: {
    async registerAccount({ commit }, payload) {
      try {
        // User Credential Interface: https://firebase.google.com/docs/reference/js/auth.usercredential
        const userCredential = await createUserWithEmailAndPassword(Auth, payload.email, payload.password)
        const user = userCredential.user
        const userProfile = {
          firstName: payload.firstName,
          lastName: payload.lastName,
          email: payload.email,
          uid: user.uid,
          emailVerified: user.emailVerified,
          isAnonymous: user.isAnonymous,
        }

        try {
          const userRef = await doc(DB, 'user', user.uid)
          await setDoc(userRef, userProfile)
          commit('updateUserState', userProfile)
        } catch (e) {
          alert('Vuex User Store (action: registerAccount) (Firebase Error):', e.message)
        }
      } catch (e) {
        alert('Vuex User Store (action: registerAccount) (Account Registration Error):', e.message)
      }
    },
    
    async loginAccount({ commit }, payload) {
      try {
        // User Credential Interface: https://firebase.google.com/docs/reference/js/auth.usercredential
        const userCredential = await signInWithEmailAndPassword(Auth, payload.email, payload.password)
        const user = userCredential.user
        const userRef = await doc(DB, 'user', user.uid)
        const userSnap = await getDoc(userRef)
        
        const idTokenResult = await user.getIdTokenResult()

        if ('idTokenResult.claims.admin && !user.isAdmin: ', idTokenResult.claims.admin && !user.isAdmin) {
          await setDoc(userRef, { isAdmin: true }, { merge: true })
        }
        
        const userProfile = {
          firstName: userSnap.data().firstName,
          lastName: userSnap.data().lastName,
          email: userSnap.data().email,
          uid: userSnap.data().uid,
          emailVerified: userSnap.data().emailVerified,
          isAnonymous: userSnap.data().isAnonymous,
        }

        if(userSnap.exists()){
          commit('updateUserState', userProfile)
        } else {
          console.log("No User Data")
        }
      } catch (e) {
        alert('Vuex User Store (action: loginAccount) (Account Login Error):', e.message)
      }
    },

    async monitorAuthState({ commit, state }) {
      onAuthStateChanged(Auth, user => {
        if (user.uid === state.uid) {
          return
        } else if (user) {
          getDoc(doc(DB, 'user', user.uid)).then((userSnap) => {
            const userProfile = {
              firstName: userSnap.data().firstName,
              lastName: userSnap.data().lastName,
              email: userSnap.data().email,
              uid: userSnap.data().uid,
              emailVerified: userSnap.data().emailVerified,
              isAnonymous: userSnap.data().isAnonymous,
            }
            commit('updateUserState', userProfile)
          })
        }
      })
    },
    signOut({ commit }) {
      signOut(Auth).then(() => {
        commit('resetUserState')
      })
    },
  }
}
