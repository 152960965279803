<template>
  <div class="home">
    <v-container fill-height>
      <v-row align="center">
        <v-col>
          <notification-signup></notification-signup>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import NotificationSignup from '@/components/NotificationSignup'
  export default {
    name: 'Home',
    components: {
      NotificationSignup
    }
  }
</script>

<style lang="scss" scoped>

</style>