export default {
  config: {
    apiKey: "AIzaSyDIYNVa9WKaZj7Bwoj7DeZn1JGTK6Y-jjs",
    authDomain: "shoplocalmo.firebaseapp.com",
    projectId: "shoplocalmo",
    storageBucket: "shoplocalmo.appspot.com",
    messagingSenderId: "1051335437823",
    appId: "1:1051335437823:web:ccb09c133e1612229e0bca",
    measurementId: "G-X49XRQ23H8"
  },
}